import React, { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router";

import Page from "../../layouts/Page/Page";
import { openSnackbar } from "../../redux/snackbar";
import { routeNames } from "../../router/routeNames";
import { deleteClient, getClient } from "../../services/clients";

function Client() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isLoading: deleting,
    error: deleteError,
    data: deleteData,
    reset: deleteReset,
  } = useMutation((id: string) => deleteClient(id));
  const { error: clientError, isLoading: loadingClient } = useQuery(
    ["client", params.id],
    () => getClient(params.id),
  );

  useEffect(() => {
    if (deleteError) {
      dispatch(
        openSnackbar({
          message: "Something went wrong!",
          type: "error",
        }),
      );
      deleteReset();
    }
  }, [deleteError]);

  useEffect(() => {
    if (!deleting && deleteData) {
      navigate(routeNames.DASHBOARD);
      dispatch(
        openSnackbar({
          message: deleteData?.data?.message,
        }),
      );
    }
  }, [deleteData]);

  useEffect(() => {
    if (!loadingClient && clientError) {
      dispatch(
        openSnackbar({
          message: "Something went wrong!",
          type: "error",
        }),
      );
    }
  }, [clientError]);

  return (
    <Page>
      <Outlet />
    </Page>
  );
}
export default Client;
