import React, {
  ChangeEventHandler,
  forwardRef,
  ReactNode,
  useState,
} from "react";
import Select, { ActionMeta } from "react-select";

import Icons from "../../assets/icons/Icons";

type CheckboxTypes = {
  name: string;
  label: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  disabled?: boolean;
  toolTipMessage?: string;
};

interface TextInputProps extends BaseInputProps {
  value?: string;
  disabled?: boolean;
}

interface AuthInputProps extends TextInputProps {
  icon: ReactNode;
  type?: "text" | "email" | "password";
  disabled?: boolean;
}

interface NumberInputProps extends BaseInputProps {
  value?: number;
}

interface SelectInputProps {
  handleChange: (newValue: any, actionMeta: ActionMeta<any>) => void;
  label: string;
  name: string;
  options: { value: string; label: string }[];
  value?: { value: string; label: string };
  placeholder?: string;
}
interface BaseInputProps {
  errors?: string[];
  handleChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  name: string;
  placeholder?: string;
  sublabel?: string;
}

// const inputClassNames =
//   "h-[38px] border border-[#E5EAF0] outline-none bg-white rounded p-2 text-[#2A263F] text-sm";

const inputClassNames = (disabled = false) => {
  const baseClass =
    "h-[38px] border border-[#E5EAF0] outline-none rounded p-2 text-[#2A263F] text-sm";
  if (disabled) {
    return `${baseClass} bg-[#F3F3F3] text-[#A1A1A1] cursor-not-allowed`; // styles when disabled
  }
  return `${baseClass} bg-white`; // styles when enabled
};

const mapErrors = (errors?: string[], inputType = "other") => {
  if (inputType === "password" && errors && errors?.length > 1) {
    return (
      <ol>
        {errors.map((error, index) => (
          <li key={error} className="text-red-500">
            {`${index + 1}. ${error}`}
          </li>
        ))}
      </ol>
    );
  }
  return (
    errors &&
    errors.map((error, i) => (
      <span key={i} style={{ color: "red" }}>
        {error}
      </span>
    ))
  );
};
export const SelectInput: React.FC<SelectInputProps> = ({
  handleChange,
  name,
  label,
  options,
  value,
  placeholder,
}) => {
  return (
    <div className=" grid grid-cols-1 gap-2">
      <label className=" text-[#42526E] text-sm font-semibold" htmlFor={name}>
        {label}
      </label>
      <Select
        name={name}
        value={value}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#E5EAF0",
            borderRadius: "5px",
            outline: "none",
            fontSize: "16px",
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#CDCDCD",
            primary: "#CDCDCD",
          },
        })}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </div>
  );
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { errors, label, name, placeholder, value, handleChange, disabled = false },
    ref,
  ) => {
    return (
      <div className="grid grid-cols-1 gap-2">
        <label
          htmlFor={name}
          className="text-[#42526E] text-sm font-semibold capitalize"
        >
          {label}
        </label>
        <input
          type="text"
          placeholder={placeholder}
          name={name}
          value={value ?? ""}
          onChange={handleChange}
          className={inputClassNames(disabled)}
          ref={ref}
          disabled={disabled}
        />
        {mapErrors(errors)}
      </div>
    );
  },
);

TextInput.displayName = "TextInput";

export const AuthInput: React.FC<AuthInputProps> = ({
  errors,
  name,
  placeholder,
  value,
  icon,
  type = "text",
  disabled = false,
  handleChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    if (!disabled) {
      setShowPassword((prevState) => !prevState);
    }
  };

  return (
    <div>
      <div
        className={`overflow-hidden flex items-center rounded border-2 w-full ${
          disabled
            ? "cursor-not-allowed bg-gray-200 border-gray-300"
            : "bg-white focus-within:border-[2px] focus-within:border-black"
        }`}
      >
        <div
          className={`flex justify-center items-center w-14 ${
            disabled ? "text-gray-500" : ""
          }`}
        >
          {icon}
        </div>
        <input
          type={type === "password" && showPassword ? "text" : type}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          value={value ?? ""}
          onChange={handleChange}
          className={`flex-1 p-4 outline-none border-l-[1px] border-black ${
            disabled ? "bg-gray-200 text-gray-500" : ""
          }`}
        />
        {type === "password" && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className={`flex justify-center items-center w-[50px] px-2 ${
              disabled ? "cursor-not-allowed text-gray-500" : "cursor-pointer"
            }`}
            disabled={disabled}
          >
            {showPassword ? <Icons.EyeCloseIcon /> : <Icons.EyeOpenIcon />}
          </button>
        )}
      </div>
      <span className="text-sm text-red-500 overflow-wrap break-words whitespace-normal">
        {mapErrors(errors, "password")}
      </span>
    </div>
  );
};

export const NumberInput: React.FC<NumberInputProps> = ({
  label,
  name,
  placeholder,
  value,
  handleChange,
}) => {
  return (
    <div className=" grid grid-cols-1 gap-2">
      <label htmlFor={name} className=" text-[#42526E] text-sm font-semibold">
        {label}
      </label>
      <input
        type="number"
        placeholder={placeholder}
        name={name}
        value={value ?? ""}
        onChange={handleChange}
        className={inputClassNames(false)}
      />
    </div>
  );
};

export const UrlInput: React.FC<TextInputProps> = ({
  errors,
  handleChange,
  label,
  name,
  placeholder,
  sublabel,
  value,
  disabled = false,
}) => {
  return (
    <div className="grid grid-cols-1 gap-2">
      <label htmlFor={name} className="text-[#42526E] text-sm font-semibold">
        {label}
        {sublabel && <span className="pl-1 text-[10px]">{sublabel}</span>}
      </label>
      <input
        type="url"
        placeholder={placeholder}
        name={name}
        value={value ?? ""}
        onChange={handleChange}
        className={inputClassNames(disabled)}
        disabled={disabled}
      />
      {mapErrors(errors)}
    </div>
  );
};

export const Checkbox: React.FC<CheckboxTypes> = ({
  name,
  label,
  handleChange,
  checked,
  disabled = false,
}) => {
  return (
    <div className="flex gap-2 items-center">
      <input
        id={name}
        type="checkbox"
        onChange={handleChange}
        checked={checked ?? false}
        className="accent-[#CD335B1F]"
        disabled={disabled}
      />
      <label className="text-[#42526E] text-sm font-semibold" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};
