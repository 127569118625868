import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Icons from "../../assets/icons/Icons";
import { routeNames } from "../../router/routeNames";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";
import {
  getClientVacancies,
  syncRecruitNowPublications,
  VACANCIES_COLUMN,
} from "../../services/vacancies";
import Breadcrumb from "../Breadcrumb";
import { Button } from "../Button/Button";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Pagination from "../Pagination/Pagination";
import Spinner from "../Spinner/Spinner";
import TableNew from "../Table/Table";

import styles from "./Vacancies.module.css";

function Vacancies() {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const campaignId = searchParams.get("campaignId");
  const { id } = useParams();
  if (id === undefined) throw Error("Invalid client");
  const clientId = id;
  const navigate = useNavigate();
  const {
    mutate: syncRecruitNowPublicationsMutate,
    data: vacienciesData,
    isLoading: syncVacanciesLoader,
  } = useMutation(() => syncRecruitNowPublications(clientId), {
    onError: (error: any) => {
      displayErrorSnackbar(error.response?.data.errors);
    },
  });

  useEffect(() => {
    if (!syncVacanciesLoader && vacienciesData) {
      displaySuccessSnackbar("Success");
    }
  }, [vacienciesData]);

  const [pageDetails, setPageDetails] = useState<any>({
    pageNumber: 1,
    pageSize: 25,
  });
  const [isOpen, setIsOpen] = useState<null | "recruitNow">();

  const { data: vacanciesData, isLoading: loadingVacacnies } = useQuery(
    [
      "vacancies",
      params.id,
      campaignId,
      pageDetails?.pageNumber,
      pageDetails?.pageSize,
    ],
    () => {
      return getClientVacancies(
        params.id,
        pageDetails?.pageNumber,
        pageDetails?.pageSize,
      );
    },
  );

  return (
    <section className="w-full">
      <div className="px-16 pb-4 pt-6 w-full ">
        <Breadcrumb
          currentPage={{
            text: "Vacancies",
            url: `/client/${params.id}/vacancies`,
          }}
          navigation={[
            { text: "Client Dashboard", url: `/client/dashboard/${params.id}` },
          ]}
        />
        <div className={styles.vacanciesHeader}>
          <h3 className=" text-2xl font-bold text-black">{t("vacancies")}</h3>

          <div className=" flex gap-x-3">
            <Button
              onClick={() => setIsOpen("recruitNow")}
              disabled={syncVacanciesLoader}
              color="tertiary"
              PrefixIcon={<Icons.CloudIcon />}
              loading={syncVacanciesLoader}
            >
              Sync RecruitNow Publications
            </Button>
          </div>
        </div>

        {loadingVacacnies ? (
          <>
            <h3>Loading vacancies...</h3>
            <Spinner size={"50px"} />
          </>
        ) : (
          <TableNew
            columns={VACANCIES_COLUMN}
            data={vacanciesData?.data?.data}
            onRowClick={(item: any) => {
              navigate(`${routeNames.CLIENT}/${clientId}/vacancies/${item.id}`);
            }}
            RowisClickable={true}
            order={true}
          />
        )}

        {vacanciesData?.data && (
          <Pagination
            pageNumber={vacanciesData?.data?.pageNumber}
            pageSize={vacanciesData?.data?.pageSize}
            totalPages={
              vacanciesData?.data?.totalRecords && vacanciesData?.data?.pageSize
                ? vacanciesData.data.totalRecords / vacanciesData.data.pageSize
                : 1
            }
            totalRecords={vacanciesData?.data?.totalRecords}
            onChange={(newDetails: any) => {
              setPageDetails(newDetails);
            }}
          />
        )}
      </div>
      <ConfirmationModal
        isOpen={isOpen === "recruitNow"}
        heading={"Confirm Sync RecruitNow Publications"}
        messageContent="Are you sure you want to continue?"
        onClose={() => setIsOpen(null)}
        handleClick={() => {
          syncRecruitNowPublicationsMutate();
          setIsOpen(null);
        }}
        modalType="info"
      />
    </section>
  );
}

export default Vacancies;
