import api from "./api";

export const JOBBOARD_COLUMNS = [
  { label: "Name", translationKey: "Name", accessor: (row: any) => row?.name },
];

export const JOBBOARD_CAMPAIGN_COLUMNS = [
  {
    label: "Client",
    translationKey: "client",
    accessor: (row: any) => row?.clientName,
  },
  {
    label: "Campaign",
    translationKey: "campaign",
    accessor: (row: any) => row?.name,
    accessKey: "campaign",
  },
  { label: "Type", translationKey: "type", accessor: (row: any) => row?.type },
  {
    label: "Budget (ex OR margin",
    translationKey: "budget",
    accessor: (row: any) => row?.budgetMarginExcluded,
  },
  {
    label: "SP (ex margin)",
    translationKey: "ex margin",
    accessor: (row: any) => row?.budgetSp,
    accessKey: "ex-margin",
  },

  {
    label: "End-date",
    translationKey: "endDate",
    accessor: (row: any) => (row?.endDate ? row?.endDate : "Infinite"),
  },
  {
    label: "Vacancies Included",
    translationKey: "vacanciesIncluded",
    accessor: (row: any) => row?.vacanciesCount,
    accessKey: "vacanciesCount",
  },
];

export const getAllJobBoards = async () => {
  const response: any = await api.get("JobBoard/GetAll");
  return response;
};

export const createJobBoard = async (data: any) => {
  const response: any = await api.post("JobBoard", data);
  return response;
};

export const deleteJobBoard = async (id: any) => {
  const response: any = await api.delete(`JobBoard/${id}`, {});
  return response;
};

export const removeCampaignJobBoard = async (id: any) => {
  const response: any = await api.delete(
    `Campaigns/DeleteCampaignJobboard/${id}`,
    {},
  );
  return response;
};

export const getAllClientJobBoard = async (id: any) => {
  const response = await api.get(`JobBoard/GetAllClientJobboards/${id}`);
  return response;
};
export const jobBoardById = async (id: any) => {
  const response: any = await api.get(`JobBoard/${id}`);
  return response;
};

export const getPlannedJobboards = async (
  year: number | null,
  month: number | null,
  clientId2: any,
) => {
  const response = await api.get(
    `BudgetPlanning/GetPlannedJobboards?year=${year}&month=${month}&clientId=${clientId2}`,
  );
  return response;
};

export const getClientJobBoardCampaigns = async (
  id: any,
  month: number,
  currentYear: number,
) => {
  const response = await api.get(
    `JobBoard/GetClientJobboardwiseOutgoingFeedCampaigns?jobBoardId=${id}&year=${currentYear}&Month=${month}`,
  );
  return response;
};

export const updateJobBoardSpAmount = async (id: any, amountSp: number) => {
  const response = await api.put(
    `BudgetPlanning/UpdateJobBoardSpAmount?id=${id}&amountSp=${amountSp}`,
    {},
  );
  return response;
};
