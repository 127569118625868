import { IoIosPin } from "react-icons/io";
import { IoArrowBackOutline } from "react-icons/io5";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";

import Icons from "../../assets/icons/Icons";
import { routeNames } from "../../router/routeNames";
import { formateAddress, htmlDecode } from "../../services/common";
import { getVacancyByIdV2 } from "../../services/vacancies";
import Breadcrumb from "../Breadcrumb";
import Spinner from "../Spinner/Spinner";

import styles from "./VacancyDetails.module.css";

const VacanyDetails = () => {
  const { vacancyId, id: clientId2 } = useParams();
  if (!vacancyId || !clientId2) throw Error("Invalid Vacancy id");
  const { isLoading, data } = useQuery(
    ["vacancy", vacancyId, clientId2],
    () => getVacancyByIdV2(clientId2, vacancyId),
    {
      refetchOnWindowFocus: false,
    },
  );
  const vacancyData = !isLoading && data ? data.data : undefined;

  let address = "";

  if (vacancyData ?? vacancyData?.vacancyLoc) {
    address = formateAddress(vacancyData.vacancyLoc);
  }

  const params = useParams();
  return (
    <section className="w-full">
      <div className="px-3 sm:px-5 md:px-16 pb-4 pt-6 w-full ">
        <Breadcrumb
          currentPage={{
            text: params.vacancyId?.toString() ?? "",
            url: `/client/${params.id}/vacancies/${params.vacancyId}`,
          }}
          navigation={[
            { text: "Client Dashboard", url: `/client/dashboard/${params.id}` },
            {
              text: "Vacancies",
              url: `/client/${params.id}/vacancies`,
            },
          ]}
        />
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="my-2">
            <PageHeader
              {...vacancyData}
              address={address}
              clientId={clientId2}
            />
            <VacancyBasicInfo
              info={{
                position: vacancyData?.position ?? "----",
                field: vacancyData?.field ?? "----",
                company: vacancyData?.company ?? "----",
                contractType: vacancyData?.contractType ?? "----",
                contactHours: vacancyData?.contractHours ?? "----",
                department: vacancyData?.department ?? "----",
                experience: vacancyData?.experience ?? "----",
                salary: vacancyData?.salary ?? "----",
                emailConsultant: vacancyData?.emailConsultant ?? "----",
                education: vacancyData?.education ?? "----",
                emailDepartment: vacancyData?.emailDepartment ?? "----",
                id: vacancyData?.id ?? "-----",
                teaser: vacancyData?.teaser ?? "----",
                phoneConsultant: vacancyData?.phoneConsultant ?? "----",
                actual: vacancyData?.actual.toString().split(" ")[0] ?? "----",
                city: vacancyData?.vacancyLoc.city ?? "----",
              }}
            />

            <VacancyDescription
              description={
                vacancyData?.description ?? "No description available"
              }
              label="Description"
            />

            <VacancyDescription
              description={vacancyData?.requirements ?? "No requirements given"}
              label="Requirements"
            />

            <VacancyDescription
              description={vacancyData?.jobOffer ?? "No data given"}
              label="Job Offer"
            />
          </div>
        )}
      </div>
    </section>
  );
};

const Item = ({ label = "", value = "" }) => {
  return (
    <div className="gap-y-1 flex flex-col mt-6">
      <span className=" text-gray-500 text-sm capitalize">{label}</span>
      <span className=" text-neutral-900 text-sm font-medium">{value}</span>
    </div>
  );
};

interface VacancyInfo {
  position?: string;
  company?: string;
  contractType?: string;
  salary?: string;
  field?: string;
  city?: string;
  department?: string;
  contactHours?: string;
  experience?: string;
  emailConsultant?: string;
  education?: string;
  id?: string;
  indeedSourcedPostingId?: string;
  teaser?: string;
  emailDepartment: string;
  phoneConsultant: string;
  actual: string;
}

const VacancyBasicInfo = ({ info }: { info: VacancyInfo }) => {
  return (
    <div className="flex flex-col sm:flex-row sm:gap-5 sm:max-w-5xl sm:justify-between flex-wrap md:flex-nowrap">
      <div className="gap-y-5">
        <Item label="Position" value={info.position} />
        <Item label="field" value={info.field} />
        <Item label="company" value={info.company} />
      </div>
      <div className="gap-y-5">
        <Item label="City" value={info?.city} />
        <Item label="Department" value={info.department} />
        <Item label="email consultant" value={info.emailConsultant} />
      </div>

      <div className="gap-y-5">
        <Item label="Salary" value={info.salary} />
        <Item label="education" value={info.education} />
        <Item label="contract type" value={info.contractType} />
      </div>
      <div className="gap-y-5">
        <Item label="publication date" value={info?.actual.split(" ")[0]} />
        <Item label="phone consultant" value={info.phoneConsultant} />
        <Item label="contract Hours" value={info.contactHours} />
      </div>
    </div>
  );
};

interface VacancyDescriptionProps {
  description: string;
  label: string;
}
const VacancyDescription = ({
  description,
  label,
}: VacancyDescriptionProps) => {
  return (
    <div className=" border-t-2 my-5 pt-5">
      <div className="text-gray-500 text-sm">{label}</div>
      <div
        dangerouslySetInnerHTML={{ __html: htmlDecode(description) }}
        className={styles.richText}
      />
    </div>
  );
};

export default VacanyDetails;

type PageHaderType = {
  title: string;
  address: string;
  link: string;
  linkApply: string;
  clientId: string;
  vacancyID: string;
  indeedSourcedPostingId: string | null;
};

const PageHeader = (props: PageHaderType) => {
  const {
    title,
    address,
    link,
    linkApply,
    clientId,
    vacancyID,
    indeedSourcedPostingId,
  } = props;
  return (
    <div className=" flex flex-col md:flex-row md:justify-between md:items-center my-5 md:my-7 gap-y-5 lg:gap-y-0 flex-wrap border-b-2 pb-10">
      <div>
        <div className=" flex gap-2">
          <Link to={`${routeNames.CLIENT}/${clientId}/vacancies`}>
            <IoArrowBackOutline size={24} />
          </Link>
          <h2 className=" font-bold mb-2">{title ?? "----"}</h2>
        </div>
        <div className=" space-y-2">
          <div className=" flex flex-col md:flex-row gap-4 md:gap-10">
            <div className="flex text-[#a3a3a3] gap-2">
              <IoIosPin size={24} />
              <span className="font-medium">{address}</span>
            </div>
            <div className="flex gap-2 items-center">
              <span className=" text-neutral-900 font-medium">Vacancy ID:</span>
              <span className=" font-medium text-[#a3a3a3]">
                {vacancyID || "--"}
              </span>
            </div>
          </div>
          {indeedSourcedPostingId && (
            <div className="flex gap-2 items-center sm:ml-2">
              <span className=" text-neutral-900 font-medium">
                Indeed Sourced Posting ID:
              </span>
              <span className=" font-medium text-[#a3a3a3]">
                {indeedSourcedPostingId || "--"}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-2 items-center">
        {link ? (
          <a
            href={link}
            className="flex items-center bg-[#CD335B] text-white px-3 py-2 gap-2 rounded"
            target="_blank"
            rel="noreferrer"
          >
            <Icons.LinkIcon height={15} width={15} color="white" />
            Link
          </a>
        ) : (
          <span>No Link &nbsp; |</span>
        )}

        {linkApply ? (
          <a
            href={linkApply}
            className="flex items-center bg-[#245ED2] text-white px-3 py-2 gap-2 rounded"
            target="_blank"
            rel="noreferrer"
          >
            <Icons.LinkApplyIcon height={15} width={15} color="white" />
            Apply
          </a>
        ) : (
          <span>No Apply Link</span>
        )}
      </div>
    </div>
  );
};
