import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";

import {
  getIndeedConfigurationV2,
  updateIndeedConfigurationV2,
} from "../../../services/clients";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../../services/common";
import { syncIndeedVacancies } from "../../../services/vacancies";
import { IndeedConfigurationType } from "../../../types/client/ClientTypes";
import { Button } from "../../Button/Button";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import { Checkbox, TextInput } from "../../FormFields/FormFields";
import Spinner from "../../Spinner/Spinner";

const defaultState = {
  enabled: false,
  indeedApply: true,
  sourceName: null,
};

const IndeedConfiguration = () => {
  const [errors, setErrors] = useState<any>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { id } = useParams();
  if (id === undefined) throw Error("Invalid client");

  const clientId = id;

  const { isLoading, data, refetch } = useQuery(
    ["indeedconfiguration", [clientId]],
    () => getIndeedConfigurationV2(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
    },
  );

  const [formData, setFormData] = useState<IndeedConfigurationType>(
    data ? data.data : defaultState,
  );

  const { mutate, isLoading: isUpdating } = useMutation(
    () => updateIndeedConfigurationV2(clientId, formData),
    {
      onSuccess: () => {
        displaySuccessSnackbar("Indeed configuration updated successfully!");
        if (formData.sourceName !== null) {
          if (data?.data.sourceName !== formData.sourceName) {
            setIsOpen(true);
          }
        }
        refetch();
      },
      onError: (error: AxiosError) => {
        setErrors(error.response?.data);
      },
    },
  );

  useEffect(() => {
    if (!isLoading && data) {
      setFormData(data.data);
    }
  }, [data, isLoading]);

  const handleInputChange = (key: string, value: any) => {
    removeValidationError(key);
    setFormData({
      ...formData,
      [key]: value === "" ? null : value,
    });
  };

  const removeValidationError = (key: string) => {
    setErrors((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      delete newErrors[key];
      return newErrors;
    });
  };

  const { mutate: syncIndeedVacanciesMutate, isLoading: syncIndeedLoader } =
    useMutation(() => syncIndeedVacancies(clientId), {
      onError: (error: any) => {
        displayErrorSnackbar(error.response?.data.errors);
      },
      onSuccess: () => {
        setIsOpen(false);
        displaySuccessSnackbar(`Success: Vacancy sync initiated successfully.`);
      },
    });

  return (
    <div className=" w-full">
      {isLoading && <Spinner />}
      {data && (
        <div>
          <div className="grid grid-cols-1 gap-5">
            <div className=" grid sm:grid-cols-2 gap-8">
              <TextInput
                label="Source Name"
                name="sourceName"
                value={formData.sourceName ?? undefined}
                handleChange={(e) =>
                  handleInputChange("sourceName", e.target.value)
                }
                errors={errors["sourceName"]}
                placeholder="Enter Source Name"
              />
            </div>
            <div className="grid sm:grid-cols-2 gap-8">
              <Checkbox
                checked={formData.indeedApply}
                handleChange={(e) =>
                  handleInputChange("indeedApply", e.target.checked)
                }
                label="Indeed apply"
                name="indeedApply"
              />
            </div>
          </div>
          <div className=" flex justify-end my-3">
            <Button
              color="secondary"
              type="button"
              loading={isUpdating}
              onClick={mutate}
            >
              Save changes
            </Button>
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={isOpen}
        closable={false}
        heading={"Sync Indeed now?"}
        onClose={() => setIsOpen(false)}
        isLoading={syncIndeedLoader}
        handleClick={() => {
          syncIndeedVacanciesMutate();
        }}
        messageContent="Do you want to synchronize vacancies with Indeed now?"
        modalType="info"
        cancelText="No"
        proceedText="Yes"
      />
    </div>
  );
};

export default IndeedConfiguration;
