import { useState, useEffect } from "react";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Select from "react-select";

import { openSnackbar } from "../../redux/snackbar";
import {
  getChannelTypeById,
  getListofClientsforChannelSpend,
} from "../../services/channels";
import {
  getBudgetByChannelYearMonth,
  updateBudgetByChannelYearMonth,
} from "../../services/channels";
import { displaySuccessSnackbar } from "../../services/common";
import Table from "../Table/Table";

import styles from "./ChannelsDataTable.module.css";

const ChannelsDataTable = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [allClientChannelsData, setAllClientChannelsData] = useState<any>([]);
  const currentDate = new Date();
  const currentMonthValue = currentDate.getMonth() + 1;
  const currentYearValue = currentDate.getFullYear();
  const currentMonthLabel = currentDate.toLocaleString("default", {
    month: "long",
  });
  const [currentMonth, setCurrentMonth] = useState<any>();
  const [currentYear, setCurrentYear] = useState<any>();
  const [currentChannel, setCurrentChannel] = useState<any>();

  useEffect(() => {
    if (id) {
      setCurrentChannel(getChannelTypeById(id));
    }
  }, [id]);

  useEffect(() => {
    setCurrentYear(currentYearValue);
  }, []);

  const channelType = getChannelTypeById(id || "");

  useEffect(() => {
    const selectdMonth = localStorage.getItem(`monthSaved${id}`);
    if (selectdMonth) {
      setCurrentMonth(JSON.parse(selectdMonth));
    } else {
      setCurrentMonth({
        value: currentMonthValue,
        label: currentMonthLabel,
      });
    }
  }, []);

  const handleChangeBudgetYear = (direction: string) => {
    if (direction === "next") {
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentYear(currentYear - 1);
    }
  };

  const { data: allchannelsData, error: channelsError } = useQuery(
    [channelType, currentYear, currentMonth?.value],
    () =>
      getBudgetByChannelYearMonth(
        channelType,
        currentYear,
        currentMonth?.value,
      ),
  );

  const { mutate } = useMutation(
    ({
      clientId,
      data,
    }: {
      clientId: number;
      data: { budgetWithoutMargin: number; spendWithoutMargin: number };
    }) =>
      updateBudgetByChannelYearMonth(
        channelType,
        currentYear,
        currentMonth.value,
        clientId,
        data,
      ),
    {
      onSuccess() {
        displaySuccessSnackbar("Changed successfully");
      },
    },
  );

  const handleParentSaved = (item: any) => {
    const { clientId, budgetWithoutMargin, spendWithoutMargin } = item;
    mutate({ clientId, data: { budgetWithoutMargin, spendWithoutMargin } });
  };

  useEffect(() => {
    if (channelsError) {
      dispatch(
        openSnackbar({
          message: "Something went wrong!",
          type: "error",
        }),
      );
    }
  }, [channelsError]);

  useEffect(() => {
    if (allchannelsData) {
      setAllClientChannelsData(allchannelsData.data);
    }
  }, [allchannelsData]);

  const onSelectMonthChange = (e: any) => {
    localStorage.setItem(`monthSaved${id}`, JSON.stringify(e));
    setCurrentMonth(e);
  };

  const handleParentChange = (value: any, index: number) => {
    const updatedData = [...allClientChannelsData];
    if (updatedData[index]) {
      updatedData[index] = {
        ...updatedData[index],
        spendWithoutMargin: Math.abs(value),
      };
    }
    setAllClientChannelsData(updatedData);
  };

  return (
    <div className=" my-12">
      <div className="flex justify-between items-center flex-wrap gap-4">
        <div className={`${styles.selectmonth} flex gap-4`}>
          <Select
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "5px",
                minWidth: "282px",
                padding: "2.5px",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "#CDCDCD",
                primary: "#CDCDCD",
              },
            })}
            value={{ value: currentMonth?.value, label: currentMonth?.label }}
            options={[
              { value: 1, label: "January" },
              { value: 2, label: "February" },
              { value: 3, label: "March" },
              { value: 4, label: "April" },
              { value: 5, label: "May" },
              { value: 6, label: "June" },
              { value: 7, label: "July" },
              { value: 8, label: "August" },
              { value: 9, label: "September" },
              { value: 10, label: "October" },
              { value: 11, label: "November" },
              { value: 12, label: "December" },
            ]}
            onChange={onSelectMonthChange}
            placeholder={"Select Month"}
          />
          <div className=" flex h-10 justify-between items-center border ml-4 px-5 rounded-2xl gap-3">
            <GrFormPrevious
              onClick={() => handleChangeBudgetYear("prev")}
              className=" w-5 h-6"
            />
            <span>{currentYear}</span>
            <MdNavigateNext
              onClick={() => handleChangeBudgetYear("next")}
              className=" w-5 h-6"
            />
          </div>
        </div>
      </div>
      <Table
        columns={getListofClientsforChannelSpend(currentChannel)}
        data={allClientChannelsData || []}
        handleChange={handleParentChange}
        handleSaved={handleParentSaved}
      />
    </div>
  );
};
export default ChannelsDataTable;
