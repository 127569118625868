import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";

import {
  createPlanningJobBoard,
  getAvailableJoBoards,
} from "../../services/budget";
import { displayErrorSnackbar } from "../../services/common";
import { Button } from "../Button/Button";

import styles from "./ClientJobBoardsModal.module.css";

type ClientJobBoardsModalProps = {
  clientId2: string;
  onClose: any;
  isOpen: boolean;
  budgetPlanningId: number;
  maxFcAllowed: number;
  activeCampaigns?: any;
  onAddNewJobBoard?: any;
  selectedClientYear?: number;
  selectedClientMonth?: number;
};

function ClientJobBoardsModal(props: Readonly<ClientJobBoardsModalProps>) {
  const { t } = useTranslation();
  const {
    clientId2,
    onClose,
    isOpen,
    budgetPlanningId,
    activeCampaigns,
    onAddNewJobBoard,
    selectedClientYear,
    selectedClientMonth,
  } = props;

  const [isValid, setIsValid] = useState<boolean>(true);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const [availabelJobBoards, setAvailabelJobBoards] = useState<any>([]);

  const [newJobBoards, setNewJobBoards] = useState<any>([]);

  const { isLoading } = useQuery(
    ["getClientJobBoard", clientId2],
    () =>
      getAvailableJoBoards(clientId2, selectedClientYear, selectedClientMonth),
    {
      onError() {
        displayErrorSnackbar("Error loading client jobBoards");
      },
      onSuccess(data) {
        const available = [];
        for (const newBoard of data.data) {
          const existing = activeCampaigns?.find(
            (prevElement: any) => prevElement?.jobboardId === newBoard?.id,
          );
          if (!existing) {
            available?.push({
              ...newBoard,
              value: newBoard?.id,
              label: newBoard?.name,
            });
          }
        }
        setAvailabelJobBoards(available);
      },
    },
  );

  const { isLoading: addingJobBoard } = useMutation(
    (data: any) => createPlanningJobBoard(data),
    {
      onSuccess() {
        onClose();
      },
    },
  );

  const onAddjobBoard = () => {
    if (!newJobBoards?.length) {
      setIsValid(false);
      return;
    }

    const jobBoardsToBeAdded = newJobBoards?.map((board: any) => {
      const campaigns = activeCampaigns?.[0]?.campaigns?.map(
        (campaign: any) => {
          return {
            ...campaign,
            budgetAmountId: 0,
            budget: 0,
            jobboardId: board?.value,
            budgetSP: 0,
          };
        },
      );

      return {
        id: 0,
        fc: 0,
        clientId2,
        campaigns,
        budgetPlanningId,
        jobboardId: board.value,
        jobboardName: board.label,
      };
    });

    onAddNewJobBoard(jobBoardsToBeAdded);
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      className={styles.afterOpen}
      ariaHideApp={false}
    >
      <section className={styles.modalContent}>
        <h3 className="text-sm font-semibold leading-6 text-[#000]">
          {t("Add new JobBoard")}
        </h3>
        <div className={styles.content}>
          <div
            className={`${styles.inputContainer} ${styles.checkboxInputContainer} text-sm font-semibold leading-6 text-[#000] `}
          >
            <span>Select all</span>
            <input
              type={"checkbox"}
              checked={availabelJobBoards?.length === newJobBoards?.length}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setNewJobBoards(availabelJobBoards);
                } else {
                  setNewJobBoards([]);
                }
              }}
            />
          </div>
          <div className={styles.inputContainer}>
            <label className="text-sm font-semibold leading-6 text-[#000]">
              JobBoard *
            </label>
            <div className={styles.selectWrap}>
              <Select
                styles={{
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor:
                      !isValid && !newJobBoards?.length ? "red" : "grey",
                    borderRadius: "5px",
                    minWidth: "100px",
                    padding: "2.5px",
                  }),
                }}
                isMulti={true}
                isLoading={isLoading}
                value={newJobBoards}
                options={availabelJobBoards}
                onChange={(value: any) => setNewJobBoards(value)}
              />
            </div>
          </div>

          <div className={styles.footer}>
            <Button color="tertiary" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={onAddjobBoard}
              loading={addingJobBoard}
            >
              Add
            </Button>
          </div>
        </div>
      </section>
    </Modal>
  );
}

export default ClientJobBoardsModal;
