import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import Icons from "../../assets/icons/Icons";
import { getClientFeedsV2 } from "../../services/clients";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";
import { FeedType } from "../../types/client/ClientTypes";
import { Button } from "../Button/Button";
import Spinner from "../Spinner/Spinner";

import CreateClientFeed from "./Feed/Create";
import EditClientFeed from "./Feed/Edit";

const ClientFeed: React.FC = () => {
  const [addNewFeed, setAddNewFeed] = useState<boolean>(false);
  const { id } = useParams();
  if (id === undefined) throw Error("Invalid feed");

  const clientId = id;

  const {
    data,
    isLoading: isLoadingFeeds,
    refetch,
  } = useQuery(["clientFeeds", clientId], () => getClientFeedsV2(clientId), {
    refetchOnWindowFocus: false,
    enabled: !!clientId,
    onError() {
      displayErrorSnackbar("Error loading client feeds");
    },
  });

  const mapDataToClientFeeds = ({
    feedName,
    id,
    feedUrl,
    feedFormat,
  }: FeedType) => ({
    feedName,
    id,
    feedUrl,
    feedFormat,
  });

  const [clientFeeds, setClientFeeds] = useState<FeedType[]>(
    data?.data.data.map(mapDataToClientFeeds) ?? [],
  );

  useEffect(() => {
    if (data) {
      setClientFeeds(data.data.data.map(mapDataToClientFeeds));
    }
  }, [data]);

  const handleCancel = () => {
    setAddNewFeed(false);
  };

  const handleFeedDeleted = (id: number) => {
    displaySuccessSnackbar("Feed deleted successfully");
    setClientFeeds((clientFeeds) => {
      const index = clientFeeds.map(({ id }) => id).indexOf(id);
      const copy = [...clientFeeds];
      copy.splice(index, 1);
      return copy;
    });
    refetch();
  };

  const handleFeedUpdated = (feed: FeedType) => {
    displaySuccessSnackbar("Feed updated successfully");
    setClientFeeds((feeds) => {
      const index = clientFeeds.map(({ id }) => id).indexOf(feed.id);
      const copy = [...feeds];
      copy[index] = feed;
      return copy;
    });
    refetch();
  };

  const handleFeedAdded = (feed: FeedType) => {
    displaySuccessSnackbar("Feed added successfully");
    setClientFeeds((feeds) => [...feeds, feed]);
    setAddNewFeed(false);
    refetch();
  };

  return (
    <div className={`relative  flex flex-col w-full`}>
      {isLoadingFeeds && <Spinner />}
      {data && (
        <div className="flex flex-col gap-5 ">
          {clientFeeds?.map((feed: FeedType) => {
            return (
              <EditClientFeed
                key={feed.id}
                clientId={clientId}
                feed={feed}
                onFeedDeleted={handleFeedDeleted}
                onFeedUpdated={handleFeedUpdated}
              />
            );
          })}

          {addNewFeed ? (
            <CreateClientFeed
              clientId={clientId}
              cancel={handleCancel}
              onFeedAdded={handleFeedAdded}
            />
          ) : (
            <div>
              <Button
                onClick={() => setAddNewFeed(true)}
                color="secondary"
                PrefixIcon={
                  <Icons.AddIcon color="red" height={15} width={15} />
                }
                variant="text"
                size="small"
              >
                Add Feed
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ClientFeed;
