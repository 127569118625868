import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import Icons from "../../assets/icons/Icons";
import { getClientContactsV2 } from "../../services/clients";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../services/common";
import { ContactType } from "../../types/client/ClientTypes";
import { Button } from "../Button/Button";
import Spinner from "../Spinner/Spinner";

import CreateClientContact from "./Contact/Create";
import EditClientContact from "./Contact/Edit";

const ClientContacts: React.FC = () => {
  const [addNewContactPerson, setAddNewContactPerson] =
    useState<boolean>(false);

  const { id } = useParams();
  if (id === undefined) throw Error("Invalid client");

  const clientId = id;

  const {
    data,
    isLoading: isLoadingContacts,
    refetch,
  } = useQuery(["contacts", clientId], () => getClientContactsV2(clientId), {
    enabled: !!clientId,
    refetchOnWindowFocus: false,
    onError() {
      displayErrorSnackbar("Error loading client contacts");
    },
  });

  useEffect(() => {
    if (data) {
      setContactPersons(data.data.data.map(mapDataToContacts));
    }
  }, [data]);

  const mapDataToContacts = ({
    email,
    id,
    isPrimary,
    name,
    phone,
    role,
  }: ContactType) => ({
    email,
    id,
    isPrimary,
    name,
    phone,
    role,
  });

  const [contactPersons, setContactPersons] = useState<ContactType[]>(
    data?.data.data.map(mapDataToContacts) ?? [],
  );

  const handleCancel = () => {
    setAddNewContactPerson(false);
  };

  const handleContactDeleted = (id: number) => {
    displaySuccessSnackbar("Contact deleted successfully");
    setContactPersons((contactPersons) => {
      const index = contactPersons.map(({ id }) => id).indexOf(id);
      const copy = [...contactPersons];
      copy.splice(index, 1);
      return copy;
    });
    refetch();
  };

  const handleContactUpdated = (contact: ContactType) => {
    displaySuccessSnackbar("Contact updated successfully");
    setContactPersons((contactPersons) => {
      const index = contactPersons.map(({ id }) => id).indexOf(contact.id);
      const copy = [...contactPersons];
      copy[index] = contact;
      return copy;
    });
    refetch();
  };

  const handleContactAdded = (contact: ContactType) => {
    displaySuccessSnackbar("Contact added successfully");
    setContactPersons((contacts) => [...contacts, contact]);
    setAddNewContactPerson(false);
    refetch();
  };

  return (
    <div className="w-full">
      {isLoadingContacts && <Spinner />}
      {data && (
        <div className="flex flex-col gap-5 max-h-[65vh] overflow-y-auto">
          {contactPersons?.map((person: ContactType) => {
            return (
              <EditClientContact
                key={person.id}
                clientId={clientId}
                person={person}
                onContactDeleted={handleContactDeleted}
                onContactUpdated={handleContactUpdated}
              />
            );
          })}
          {addNewContactPerson ? (
            <CreateClientContact
              clientId={clientId}
              cancel={handleCancel}
              onContactAdded={handleContactAdded}
            />
          ) : (
            <div>
              <Button
                onClick={() => setAddNewContactPerson(true)}
                color="secondary"
                variant="text"
                size="small"
                PrefixIcon={
                  <Icons.AddIcon color="red" height={15} width={15} />
                }
              >
                Add Contact
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ClientContacts;
