import { AxiosError } from "axios";
import { useState } from "react";
import { useMutation } from "react-query";

import useFormErrors from "../../../hooks/useFormErrors";
import {
  deleteClientContactsV2,
  updateClientContactsV2,
} from "../../../services/clients";
import { ContactType } from "../../../types/client/ClientTypes";
import { Button } from "../../Button/Button";
import { FormItem } from "../FormItem";

interface EditClientContactProps {
  clientId: string;
  person: ContactType;
  onContactDeleted: (id: number) => void;
  onContactUpdated: (contact: ContactType) => void;
}

const EditClientContact: React.FC<EditClientContactProps> = (props) => {
  const { clientId, person, onContactDeleted, onContactUpdated } = props;

  const [isEditing, setIsEditing] = useState(person.id === undefined);
  const [formData, setFormData] = useState<ContactType>(person);
  const { errors, setFormErrors, removeFormError } = useFormErrors();

  const { mutate: updateContact } = useMutation(
    (id: number) => updateClientContactsV2(formData, clientId, id),
    {
      onSuccess(response) {
        setIsEditing(false);
        onContactUpdated(response.data);
      },
      onError(e: AxiosError) {
        setFormErrors(e.response?.data);
      },
    },
  );

  const { mutate: deleteContact } = useMutation(
    (id: number) => deleteClientContactsV2(clientId, id),
    {
      onSuccess(response) {
        onContactDeleted(response.data.id);
      },
    },
  );

  const handleInputChange = (key: string, value: any) => {
    removeFormError(key);
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormData(person);
  };

  const handleSave = () => {
    updateContact(person.id);
  };

  const handleDelete = () => {
    deleteContact(person.id);
  };

  return (
    <div
      className={`bg-[#F8F7FC] border border-[#F8F7FC] rounded p-5 shadow-sm w-full`}
    >
      <div className="flex justify-between mb-3">
        <label className="text-black font-semibold text-sm uppercase">
          {formData.name}
          {formData.isPrimary && " - Primary"}
        </label>
        <div className="flex items-center gap-4">
          {isEditing ? (
            <>
              <Button
                onClick={handleCancel}
                color="secondary"
                variant="text"
                size="small"
              >
                Cancel
              </Button>

              <Button
                onClick={handleSave}
                color="secondary"
                variant="text"
                size="small"
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              onClick={() => setIsEditing(true)}
              color="secondary"
              variant="text"
              size="small"
            >
              Edit
            </Button>
          )}
          {!formData.isPrimary && (
            <>
              |
              <Button
                onClick={handleDelete}
                color="secondary"
                variant="text"
                size="small"
                disabled={formData.isPrimary}
              >
                Delete
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <FormItem errors={errors["name"]} label="Name">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
            disabled={!isEditing}
          />
        </FormItem>
        <FormItem errors={errors["phone"]} label="Phone">
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={(e) => handleInputChange("phone", e.target.value)}
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
            disabled={!isEditing}
          />
        </FormItem>
      </div>

      <div className="flex flex-col lg:flex-row gap-5 mt-5">
        <FormItem errors={errors["email"]} label="Email">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
            disabled={!isEditing}
          />
        </FormItem>
        <FormItem errors={errors["role"]} label="Role">
          <input
            type="text"
            name="role"
            value={formData.role}
            onChange={(e) => handleInputChange("role", e.target.value)}
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
            disabled={!isEditing}
          />
        </FormItem>
      </div>
    </div>
  );
};

export default EditClientContact;
