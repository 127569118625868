import { useState } from "react";
import { useMutation } from "react-query";
import Select from "react-select";

import useFormErrors from "../../../hooks/useFormErrors";
import { addNewFeedV2 } from "../../../services/clients";
import {
  displayErrorSnackbar,
  displaySuccessSnackbar,
} from "../../../services/common";
import { feedFormatOptions } from "../../../services/feeds";
import { CreateFeedDto, FeedType } from "../../../types/client/ClientTypes";
import { Button } from "../../Button/Button";
import Spinner from "../../Spinner/Spinner";
import { FormItem } from "../FormItem";

interface CreateClientFeedProps {
  clientId: string;
  cancel: () => void;
  onFeedAdded: (contact: FeedType) => void;
}

const CreateClientFeed: React.FC<CreateClientFeedProps> = ({
  clientId,
  cancel,
  onFeedAdded,
}) => {
  const [formData, setFormData] = useState<CreateFeedDto>({
    feedName: "",
    feedUrl: "",
    feedFormat: "DefaultRecruitNow",
  });
  const { errors, setFormErrors, removeFormError } = useFormErrors();

  const handleInputChange = (key: string, value: any) => {
    removeFormError(key);
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const { mutate: addContactMutate, isLoading } = useMutation(
    (data: CreateFeedDto) => addNewFeedV2(data, clientId),
    {
      onSuccess(response) {
        displaySuccessSnackbar("Contact added successfully");
        onFeedAdded(response.data);
      },
      onError(e: any) {
        if (e.response?.data) {
          const errors = e.response.data;
          if (errors["clientType"] && Array.isArray(errors["clientType"])) {
            displayErrorSnackbar(errors["clientType"][0]);
          }
          setFormErrors(errors);
        }
      },
    },
  );

  const handleSubmit = () => {
    addContactMutate(formData);
  };

  return (
    <div
      className={`bg-[#F8F7FC] border border-[#F8F7FC] rounded p-5 shadow-sm w-full relative`}
    >
      {isLoading && (
        <Spinner
          className="z-50 absolute w-full h-full flex justify-center items-center"
          size={50}
        />
      )}
      <div className="flex justify-between mb-3">
        <span className="text-black font-semibold text-sm uppercase">NEW</span>
        <div className="flex items-center gap-4">
          <Button
            onClick={cancel}
            size="small"
            color="secondary"
            variant="text"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="secondary"
            size="small"
            variant="text"
          >
            Save
          </Button>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <FormItem label="Feed Name" errors={errors["feedName"]}>
          <input
            type="text"
            name="feedName"
            value={formData.feedName}
            onChange={(e) => handleInputChange("feedName", e.target.value)}
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
          />
        </FormItem>
      </div>

      <div className="flex flex-col lg:flex-row gap-5 mt-5">
        <FormItem label="Feed Url" errors={errors["feedUrl"]}>
          <input
            type="url"
            name="feedUrl"
            value={formData.feedUrl}
            onChange={(e) =>
              handleInputChange("feedUrl", e.target.value.trim())
            }
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
          />
        </FormItem>
        <FormItem label="Feed Format" errors={errors["feedFormat"]}>
          <Select
            value={feedFormatOptions.find(
              (item) => item.value === formData.feedFormat,
            )}
            onChange={(selection: any) =>
              handleInputChange("feedFormat", selection.value)
            }
            options={feedFormatOptions}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: "#E5EAF0",
                borderRadius: "5px",
                outline: "none",
                fontSize: "16px",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#CDCDCD",
                primary: "#CDCDCD",
              },
            })}
          />
        </FormItem>
      </div>
    </div>
  );
};

export default CreateClientFeed;
