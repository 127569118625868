import { AxiosError } from "axios";
import { useState } from "react";
import { useMutation } from "react-query";

import useFormErrors from "../../../hooks/useFormErrors";
import { addNewContactV2 } from "../../../services/clients";
import { displaySuccessSnackbar } from "../../../services/common";
import {
  ContactType,
  CreateContactType,
} from "../../../types/client/ClientTypes";
import { Button } from "../../Button/Button";
import { FormItem } from "../FormItem";

interface CreateClientContactProps {
  clientId: string;
  cancel: () => void;
  onContactAdded: (contact: ContactType) => void;
}

const CreateClientContact: React.FC<CreateClientContactProps> = (Props) => {
  const [formData, setFormData] = useState<CreateContactType>({
    name: "",
    phone: "",
    email: "",
    role: "",
    isPrimary: false,
  });

  const { clientId, cancel, onContactAdded } = Props;
  const { errors, removeFormError, setFormErrors } = useFormErrors();

  const handleInputChange = (key: string, value: any) => {
    removeFormError(key);
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const { mutate: addContactMutate } = useMutation(
    (data: CreateContactType) => addNewContactV2(data, clientId),
    {
      onSuccess(response) {
        displaySuccessSnackbar("Contact added successfully");
        onContactAdded(response.data);
      },
      onError(e: AxiosError) {
        setFormErrors(e.response?.data);
      },
    },
  );

  const handleSubmit = () => {
    addContactMutate(formData);
  };

  return (
    <div
      className={`bg-[#F8F7FC] border border-[#F8F7FC] rounded p-5 shadow-sm w-full`}
    >
      <div className="flex justify-between mb-3">
        <span className="text-black font-semibold text-sm uppercase">NEW</span>
        <div className="flex items-center gap-4">
          <Button
            onClick={cancel}
            color="secondary"
            variant="text"
            size="small"
          >
            Cancel
          </Button>
          |
          <Button
            onClick={() => handleSubmit()}
            color="secondary"
            variant="text"
            size="small"
          >
            Save
          </Button>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <FormItem label="Name" errors={errors["name"]}>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
          />
        </FormItem>
        <FormItem label="Phone" errors={errors["phone"]}>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={(e) => handleInputChange("phone", e.target.value)}
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
          />
        </FormItem>
      </div>

      <div className="flex flex-col lg:flex-row gap-5 mt-5">
        <FormItem label="Email" errors={errors["email"]}>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
          />
        </FormItem>
        <FormItem errors={errors["role"]} label="Role">
          <input
            type="text"
            name="role"
            value={formData.role}
            onChange={(e) => handleInputChange("role", e.target.value)}
            className={`disabled:bg-[#F2F2F2] border outline-none bg-white rounded p-2 text-[#2A263F] text-base`}
          />
        </FormItem>
      </div>
    </div>
  );
};

export default CreateClientContact;
